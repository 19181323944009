@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  transition: background-color 100ms ease;
  transition: color 100ms ease;
  transition: border-color 100ms ease;
  font-family: 'Roboto', sans-serif;
}

:root {
  --color-bg: #121212;
  --color-depth1: #1f1f1f;
  --color-depth2: #222222;
  --color-depth3: #262626;
  --color-depth4: #282828;
  --color-depth5: #2c2c2c;
  --color-depth6: #2f2f2f;
  --color-depth7: #333333;
  --color-depth8: #353535;
  --color-depth9: #383838;
  --color-secondaryPale: #ff7597;
  --color-secondary: #f50057;
  --color-primary: #0bdbc5;
  --color-error: #cf6679;
  --color-alert: #017957;
  --color-active: #bb014e;
  --color-onPrimary: #121212;
  --color-onSecondary: #ffffff;
  --button-outline: rgba(255, 255, 255, 0.23);
  --color-text: #ffffff;
  --color-textHinted: rgba(255, 255, 255, 0.5);
}

/* span {
  color: var(--color-text);
} */

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0);
  width: 10px;
  height: 10px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
::-webkit-scrollbar-track:hover {
  background-color: rgba(210, 210, 210, 0.15);
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: rgba(189, 163, 163, 0.3);
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(210, 210, 210, 0.5);
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.lightMode {
  --color-bg: #e0dede;
  --color-depth1: #ffffff;
  --color-depth2: #f2f6f6;
  --color-depth3: #e6eeee;
  --color-depth4: #dae5e6;
  --color-depth5: #ceddde;
  --color-depth6: #c1d4d5;
  --color-depth7: #b5cbcd;
  --color-depth8: #90b1b4;
  --color-depth9: #84a9ac;
  --color-secondaryPale: #ff7597;
  --color-secondary: #f50057;
  --color-primary: #10ccb9;
  --color-error: #cf6679;
  --color-alert: #017957;
  --color-active: #bb014e;
  --color-onPrimary: rgba(37, 38, 39, 0.87);
  --color-onSecondary: #ffffff;
  --color-text: rgba(37, 38, 39, 0.87);
  --color-textHinted: rgba(37, 38, 39, 0.38);
  --button-outline: rgba(0, 0, 0, 0.23);
}

.drag * {
  pointer-events: none;
}

@media (hover: none) and (pointer: coarse) {
  .hideOverflow {
    overflow: hidden;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.paxDropdown .MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid;
}

.paxDropdown input {
  color: #ffffff !important;
}

.lightMode .paxDropdown input {
  color: #000000 !important;
  opacity: 1 !important;
}

.paxDropdown label {
  color: rgba(255, 255, 255, 0.54) !important;
}

.lightMode .paxDropdown label {
  color: rgba(37, 38, 39, 0.54) !important;
}

.keyRow {
  width: 100%;
  height: 63px;
  display: flex;
  justify-content: space-between;
}

.key {
  width: 33%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.key:hover {
  background: rgba(235, 235, 235, 0.11);
}

.react-rte > div:first-child {
  margin: 0;
  border-bottom: none;
  position: absolute;
  bottom: 0px;
  right: 5px;
  z-index: 100;
  padding: 0;
}

.inline-btn {
  background: #c1d4d5 !important;
  filter: invert(1);
  z-index: 10;
}

.inline-btn.IconButton__isActive___2Ey8p {
  background: #84a9ac !important;
}

.lightMode .inline-btn {
  filter: invert(0);
}

.image-grid {
  display: grid;
  width: 100%;
  min-height: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* grid-auto-rows: 150px; */
  grid-gap: 10px;
}

.image-grid > div {
  padding: 10px;
  min-height: 170px;
  border-radius: 5px;
}

.image-grid > div:hover {
  background: rgba(0, 0, 0, 0.3);
}

.image-grid .image {
  width: 100%;
  height: 150px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.current-image .image {
  width: 100%;
  height: 25vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.current-image {
  width: 100%;
  position: relative;
}

.current-image .close {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
}

.multiple-image {
  border-radius: 4px;
  position: relative;
  border: 2px transparent solid;
}

.multiple-image .multiple-image-checkbox {
  display: none;
  position: absolute;
  top: -4px;
  right: -4px;
}

.multiple-image svg {
  fill: dodgerblue;
  width: 28px;
  height: 28px;
}

.multiple-image:hover,
.multiple-image.selected {
  border: 2px dodgerblue solid;
}

.multiple-image:hover .multiple-image-checkbox,
.multiple-image.selected .multiple-image-checkbox {
  display: block;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
  color: unset !important;
}

.settings-main {
  flex: 1;
  max-width: 900px;
  width: 100%;
  margin: auto;
  padding-top: 20px;
}

.table-alert {
  fill: #ffc107;
  fill-opacity: 1;
}
